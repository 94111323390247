import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, withRouter, Link } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import MenuItem from '@material-ui/core/MenuItem';
import { styled, withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import { switchCompany, fetchAllCompanies, resetIntegrationsData } from 'store/actions';
import s from './SidenavContent.module.css';

import CollapseBox from './CollapseBox';
import IntegrationsMenu from './IntegrationsMenu/IntegrationsMenu';
import generateCompanyObject from '../../util/switchCompanyObject';


const SidebarDivider = styled(Divider)({
  backgroundColor: 'rgba(255, 255, 255, 0.6)'
});

const SidebarBadge = styled(Badge)({
  top: '16px',
  right: '40px',
  position: 'absolute'
});

const styles = {
  companiesListStyles: {
    height: '350px',
  },
  companiesRootStyles: {
    top: '130px !important',
  }
};


function renderBadge(data) {
  if (data) {
    return (
      <SidebarBadge
        badgeContent={data.badge}
        color="primary"
        title={data.message}
        // eslint-disable-next-line react/no-children-prop
        children={[]}
      />
    );
  }

  return null;
}

function SidenavContent({ classes, location }) {
  const dispatch = useDispatch();

  const allCompanies = useSelector(state => state.company.allCompanies);
  const currentCompany = useSelector(state => state.company.currentCompany);
  const isSuperuser = useSelector(state => state.auth.isSuperuser);
  const isCompanyLoading = useSelector(state => state.company.loader);

  const isActiveCompanyMenu = () => {
    const path = location.pathname.slice(1);
    const subPath = path.split('/');
    return subPath.length === 3;
  };

  const { isSecurity, isFullAccess } = currentCompany;

  const handleChange = () => (event) => {
    event.preventDefault();

    const companyId = event.target.value;

    dispatch(resetIntegrationsData());

    const selectedCompany = allCompanies.find(company => company.id === companyId);
    const preparedSelectedCompany = generateCompanyObject(selectedCompany || currentCompany);
    dispatch(switchCompany(preparedSelectedCompany));

  };

  const handleOpenSelectCompany = () => {
    dispatch(fetchAllCompanies({ currentCompanyID: currentCompany.id }));
  };

  const renderCompanies = (allCompanies) => {
    if (allCompanies) {
      return allCompanies.map(company => (
        <MenuItem
          disabled={isCompanyLoading}
          key={company.id}
          value={company.id}
        >
          {company.name}
        </MenuItem>
      ));
    }
    return null;
  };

  const getUrlString = currentCompany => `/app/companies/${currentCompany.id}`;

  const getNavLink = (companyId, item) => `/app/companies/${companyId}/${item}`;


  if (currentCompany.id) {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li>
            <NavLink to="/app">
              <i className="zmdi zmdi-home zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.home" />
              </span>
            </NavLink>
          </li>
          <li>

            <Select
              id="company"
              label="Companies"
              value={currentCompany.id}
              onChange={handleChange()}
              onOpen={handleOpenSelectCompany}
              fullWidth
              MenuProps={{
                classes: {
                  paper: classes.companiesRootStyles,
                  list: classes.companiesListStyles,
                }
              }}
              className="select-company"
              classes={{
                root: 'bg-light',
              }}
            >
              {renderCompanies(allCompanies)}
            </Select>

          </li>
          {(isSecurity && !isFullAccess) && !isSuperuser ? (
            null
          ) : (
            <li className="bg-light change-company">
              <Link to={getUrlString(currentCompany)}>
                <i className="zmdi zmdi-edit zmdi-hc-ul" />
                <span><IntlMessages id="sidebar.edit" /></span>
              </Link>
            </li>
          )}
          <li className="nav-header">
            <span><IntlMessages id="sidebar.admin" /></span>
          </li>
          <li>
            <NavLink activeClassName={s.activeLink} className={`${isActiveCompanyMenu() ? s.activeLink : ''}`} to="/app/companies" exact>
              <i className="zmdi zmdi-hq zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.companies" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={s.activeLink} to={getNavLink(currentCompany.id, 'buildings')}>
              <i className="zmdi zmdi-store zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.buildings" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={s.activeLink} to={getNavLink(currentCompany.id, 'vunits')}>
              <i className="zmdi zmdi-tablet-android zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.visitorUnits" />
                {(isFullAccess || isSuperuser)
                  ? renderBadge(currentCompany.notifications && currentCompany.notifications.visitor_units)
                  : null}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={s.activeLink} to={getNavLink(currentCompany.id, 'doorsControllers')}>
              <i className="zmdi zmdi-balance zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.doorsControllers" />
                {(isFullAccess || isSuperuser)
                  ? renderBadge(currentCompany.notifications && currentCompany.notifications.access_control_boards)
                  : null}
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={s.activeLink} to={getNavLink(currentCompany.id, 'vcodes')}>
              <i className="zmdi zmdi-camera-front zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.visitorCodes" />
              </span>
            </NavLink>
          </li>
          {(isSecurity && !isFullAccess) && !isSuperuser ? (
            null
          ) : (
            <li>
              <NavLink activeClassName={s.activeLink} to={getNavLink(currentCompany.id, 'admins')}>
                <i className="zmdi zmdi-account zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.companyAdmins" />
                </span>
              </NavLink>
            </li>
          )}
        </ul>
        <SidebarDivider />
        <ul className="nav-menu">
          <li className="nav-header">
            <span><IntlMessages id="sidebar.members" /></span>
          </li>
          {(isSecurity && !isFullAccess) && !isSuperuser ? (
            null
          ) : (
            <li>
              <NavLink activeClassName={s.activeLink} to={getNavLink(currentCompany.id, 'schedules')}>
                <i className="zmdi zmdi-time zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.schedules" />
                </span>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink activeClassName={s.activeLink} to={getNavLink(currentCompany.id, 'accessLevels')}>
              <i className="zmdi zmdi-nfc zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.accessLevels" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={s.activeLink} to={getNavLink(currentCompany.id, 'members')}>
              <i className="zmdi zmdi-accounts zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.members" />
              </span>
            </NavLink>
          </li>
        </ul>
        <SidebarDivider />


        <IntegrationsMenu />


        <SidebarDivider />
        <ul className="nav-menu">
          <li className="nav-header">
            <span><IntlMessages id="sidebar.loggingHistory" /></span>
          </li>
          <li>
            <NavLink activeClassName={s.activeLink} to={getNavLink(currentCompany.id, 'visitorImages')}>
              <i className="zmdi zmdi-collection-image zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.visitorImages" />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName={s.activeLink} to={getNavLink(currentCompany.id, 'events')}>
              <i className="zmdi zmdi-receipt zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.eventsTransactions" />
              </span>
            </NavLink>
          </li>
          <CollapseBox
            items={[
              { name: <IntlMessages id="sidebar.doors" />, url: 'doorsReport' },
              { name: <IntlMessages id="sidebar.events" />, url: 'eventsReport' },
              { name: <IntlMessages id="sidebar.members" />, url: 'membersReport' },
              { name: <IntlMessages id="sidebar.visitorCodes" />, url: 'visitorCodesReport' },
              { name: <IntlMessages id="sidebar.errors" />, url: 'errorsReport' }
            ]}
            collapseLabel={<IntlMessages id="sidebar.reports" />}
            iconStyle="zmdi-view-web"
          />
        </ul>
      </CustomScrollbars>
    );
  }
  return null;
}

export default withRouter(withStyles(styles)(SidenavContent));
